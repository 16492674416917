// ksr_manager/readtest/queryReadTestList
export const READTEST = 'ksr_manager/readtest';
export const EVALUATION = 'ksr_manager/evaluation';
export const EVALUATIONSINGLE = 'ksr_manager/evaluationSingle';
export const queryReadTestList = `${EVALUATIONSINGLE}/querySingleEvaluationList`;
export const modifySingleEvaluation = `${EVALUATIONSINGLE}/modifySingleEvaluation`;
export const deleteSingleEvaluation = `${EVALUATIONSINGLE}/deleteSingleEvaluation`;
export const addSingleEvaluationQuestions = `${EVALUATIONSINGLE}/addSingleEvaluationQuestions`;
export const deleteReadTest = `${EVALUATION}/deleteSingleEvaluation`;
export const importEvaluationQuestion = `${EVALUATION}/importEvaluationQuestion`;
export const queryGuidanceBooksForReadTest = `${READTEST}/queryGuidanceBooksForReadTest`;
export const queryQuestionList = `${EVALUATIONSINGLE}/querySingleEvaluationQuestions`;
export const deleteQuestion = `${EVALUATION}/deleteSingleEvaluationQuestion`;
export const deleteSingleEvaluationQuestion = `${EVALUATIONSINGLE}/deleteSingleEvaluationQuestion`;
export const queryQuestionsByCondition = `${EVALUATION}/queryQuestionsByCondition`;
export const addEvaluationQuestion = `${EVALUATION}/addEvaluationQuestion`;
export const modifyEvaluationQuestion = `${EVALUATION}/modifyEvaluationQuestion`;
export const queryQuestionDetail = `${EVALUATION}/queryQuestionDetail`;
export const makeQuestionAuditNotPass = `${EVALUATION}/makeQuestionAuditNotPass`;
export const makeQuestionAuditPass = `${EVALUATION}/makeQuestionAuditPass`;
export const deleteQuestionBatch = `${EVALUATION}/deleteQuestionBatch`;
export const queryEvaluationLabels = `${EVALUATION}/queryEvaluationLabels`;
export const addSingleEvaluation = `${EVALUATIONSINGLE}/addSingleEvaluation`;
export const getUploadId = `ksr_manager/fileUpload/getUploadId`;
// 上传
export const upload = `ksr_manager/fileUpload/upload`;
// 单本测评上架
export const putOnShelfSingleEvaluation = `${EVALUATIONSINGLE}/putOnShelfSingleEvaluation`;
// 单本测评下架
export const pullOffShelfSingleEvaluation = `${EVALUATIONSINGLE}/pullOffShelfSingleEvaluation`;
export const querySingleScoreList = `${EVALUATIONSINGLE}/querySingleScoreList`;
// 查询单本导读手册
export const queryResourceInfoById = `ksr_manager/resourceManager/queryResourceInfoById`;
export const queryEvaluationResultList = `${EVALUATION}/queryEvaluationResultList`;
export const queryEvaluationScoreList = `${EVALUATION}/queryEvaluationScoreList`;
export const queryEvaluationScoreDetail = `${EVALUATION}/queryEvaluationScoreDetail`;
export const querySingleDetail = `ksr_manager/evaluationSingle/querySingleDetail`;
export const apiUrl = {
  queryReadTestList,
  deleteReadTest,
  queryQuestionList,
  deleteQuestion,
  queryQuestionsByCondition,
  addEvaluationQuestion,
  addSingleEvaluation,
  queryGuidanceBooksForReadTest,
  getUploadId,
  upload,
  queryQuestionDetail,
  modifyEvaluationQuestion,
  makeQuestionAuditNotPass,
  makeQuestionAuditPass,
  deleteQuestionBatch,
  queryEvaluationLabels,
  modifySingleEvaluation,
  deleteSingleEvaluation,
  deleteSingleEvaluationQuestion,
  addSingleEvaluationQuestions,
  putOnShelfSingleEvaluation,
  pullOffShelfSingleEvaluation,
  queryResourceInfoById,
  importEvaluationQuestion
};
