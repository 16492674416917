export enum UploadConfigEnum {
  banner = 1, // Banner
  eBookResource = 2, // 电子书资源
  audioResource = 3, // 有声书资源
  videoResource = 4, // 视频课程资源
  resourceCover = 5, // 资源封面
  readingTest = 6, // 阅读检测
  classifyManagement = 7, // 分类管理
  guideBook= 10, // 导读手册
  evaluation = 11,  // 测评
  listenPicBook = 15,  // 绘本听书
  speakPicBook = 16,  // 绘本讲书
  solutionPicBook = 17,  // 绘本解书
  picContent = 18,  // 图片内容
  writer = 20, // 作家
  article = 21, // 专栏文章
  specialtopic = 22, // 专栏文章
  hdimgEpub = 23, // 专栏文章
  hdimgPicbook = 24, // 专栏文章
}

