const RESOURCE = '/ksr_manager';

export const userStatisticApiUrl = {
  // 各年级资源分类统计
  queryGradeResourceStatistical: `${RESOURCE}/userStatistical/queryGradeResourceStatistical`,
  // 查询各年级资源总数和阅读总数对比
  queryGradeResourceCount: `${RESOURCE}/userStatistical/queryGradeResourceCount`,
  // 各年级书籍阅读人数top5
  queryReadingResourceTopByGrade: `${RESOURCE}/userStatistical/queryReadingResourceTopByGrade`,
  // 查询各年级阅读平局时间
  queryAvgReadingTimeByGrade: `${RESOURCE}/userStatistical/queryAvgReadingTimeByGrade`,
  // 阅读总人数
  queryReadingCount: `${RESOURCE}/userStatistical/queryReadingCount`,
};

export const bookStatisticApiUrl = {
  // 各年级资源分类统计
  queryResourceStatistical: `${RESOURCE}/resourceStatistical/queryResourceStatistical`,
};

export const readStatisticApiUrl = {
  // 推荐书籍转化阅读计划、阅读、购买次数列表
  queryRecommendBookList: `${RESOURCE}/readingStatistical/queryRecommendBookList`,
  queryReadingStatisticalList: `${RESOURCE}/readingStatistical/queryReadingStatisticalList`,
};
